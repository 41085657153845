var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"editOrder",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('statistic-card-horizontal',{staticClass:"border shadow-none",attrs:{"statistic":_vm.order.reference_id,"statistic-title":"Order ID","icon":"TruckIcon"}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.handleSubmit)}}},[_c('b-card',{staticClass:"border shadow-none"},[_c('b-card-body',[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"PO Number"}},[_c('ValidationProvider',{attrs:{"name":"PO Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"po_number","state":errors.length > 0 ? false : null,"placeholder":"Area"},model:{value:(_vm.form.po_number),callback:function ($$v) {_vm.$set(_vm.form, "po_number", $$v)},expression:"form.po_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"PO Expiry Date"}},[_c('ValidationProvider',{attrs:{"name":"PO Expiry Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"po_expiry_date","config":{enableTime: true, dateFormat: 'Y-m-d', minDate: _vm.defaultPoExpiryDate}},model:{value:(_vm.form.po_expiry_date),callback:function ($$v) {_vm.$set(_vm.form, "po_expiry_date", $$v)},expression:"form.po_expiry_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Delivery Date"}},[_c('ValidationProvider',{attrs:{"name":"Delivery Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"delivery_date","config":{dateFormat: 'Y-m-d H:i', minDate: _vm.defaultDeliveryDateTime}},model:{value:(_vm.form.delivery_date),callback:function ($$v) {_vm.$set(_vm.form, "delivery_date", $$v)},expression:"form.delivery_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Invoice Number"}},[_c('ValidationProvider',{attrs:{"name":"Invoice Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"invoice_number","state":errors.length > 0 ? false : null,"placeholder":"Invoice Number"},model:{value:(_vm.form.invoice_number),callback:function ($$v) {_vm.$set(_vm.form, "invoice_number", $$v)},expression:"form.invoice_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)],1),_c('b-col',{staticClass:"mb-2 text-center"},[_c('b-button',{attrs:{"variant":"danger","disabled":_vm.isLoading},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[(_vm.isLoading)?_c('b-spinner'):_c('span',[_vm._v(" Submit ")])],1)],1)],1)])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <validation-provider
    v-slot="{ errors }"
    :vid="vidName"
    name="State"
  >
    <v-select
      v-model="selectedState"
      :options="states"
      :clearable="false"
      label="text"
      :reduce="state => state.value"
      append-to-body
      :calculate-position="withPopper"
      placeholder="Select a State"
      :disabled="!isEditable"
      @input="handleSetSelectedState"
    />
    <span class="text-danger">{{ errors[0] }}</span>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { createPopper } from '@popperjs/core'
import vSelect from 'vue-select'

export default {
  name: 'MySelectStates',
  components: {
    vSelect,
    ValidationProvider,
  },
  props: {
    state: {
      type: String,
      default: '',
    },
    vidName: {
      type: String,
      default: 'state',
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      states: [
        { text: 'Kuala Lumpur', value: 'Kuala Lumpur' },
        { text: 'Perlis', value: 'Perlis' },
        { text: 'Kedah', value: 'Kedah' },
        { text: 'Pulau Pinang', value: 'Pulau Pinang' },
        { text: 'Perak', value: 'Perak' },
        { text: 'Pahang', value: 'Pahang' },
        { text: 'Kelantan', value: 'Kelantan' },
        { text: 'Terengganu', value: 'Terengganu' },
        { text: 'Selangor', value: 'Selangor' },
        { text: 'Negeri Sembilan', value: 'Negeri Sembilan' },
        { text: 'Melaka', value: 'Melaka' },
        { text: 'Johor', value: 'Johor' },
        { text: 'Sabah', value: 'Sabah' },
        { text: 'Sarawak', value: 'Sarawak' },
        { text: 'Singapore', value: 'Singapore' },
      ],
      selectedState: null,
    }
  },
  created() {
    this.selectedState = this.state
  },
  methods: {
    handleSetSelectedState() {
      this.$emit('set-selected-state', this.selectedState)
    },
    withPopper(dropdownList, component, { width }) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      const tempDropDown = dropdownList
      tempDropDown.style.width = width
      tempDropDown.style.zIndex = '1300'

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, tempDropDown, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top',
              )
            },
          },
        ],
      })

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy()
    },
  },
}
</script>

<template>
  <validation-provider
    v-slot="{ errors }"
    :vid="vidName"
    name="Type of Market"
  >
    <v-select
      v-model="selectedMarket"
      :options="markets"
      :clearable="false"
      label="name"
      :reduce="market => market.id"
      append-to-body
      :calculate-position="withPopper"
      placeholder="Select Type of Market"
      :disabled="!isEditable"
      @input="handleSetSelectedMarket"
    />
    <span class="text-danger">{{ errors[0] }}</span>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { createPopper } from '@popperjs/core'
import vSelect from 'vue-select'
import axios from '@axios'

export default {
  name: 'MySelectTypeOfMarket',
  components: {
    vSelect,
    ValidationProvider,
  },
  props: {
    market: {
      type: Number,
      default: null,
    },
    vidName: {
      type: String,
      default: 'type',
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      markets: [],
      selectedMarket: null,
    }
  },
  watch: {
    market(newValue) {
      this.selectedMarket = newValue
    },
  },
  created() {
    this.fetchMarkets()
    this.selectedMarket = this.market
  },
  methods: {
    async fetchMarkets() {
      const response = await axios.get('/markets')
      if (response.status === 200) {
        this.markets = response.data
      }
    },
    handleSetSelectedMarket() {
      this.$emit('set-selected-market', this.selectedMarket)
    },
    withPopper(dropdownList, component, { width }) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      const tempDropDown = dropdownList
      tempDropDown.style.width = width
      tempDropDown.style.zIndex = '1300'

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, tempDropDown, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top',
              )
            },
          },
        ],
      })

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy()
    },
  },
}
</script>

<template>
  <div>
    <b-card class="shadow-none">
      <b-row>
        <b-col
          v-if="!refundType"
          class="text-center"
        >
          <h3>Refund Option</h3>
          <b-row class="mt-2">
            <b-col
              cols="6"
            >

              <b-card
                title="Store Credit"
                style="max-width: 20rem;"
                class="m-auto cursor-pointer"
                @click="handleSetSelectedRefundOption(2)"
              >
                <img
                  src="@/assets/images/logo/stack-coins.png"
                  alt=""
                  class="mb-2"
                >
                <b-card-text>
                  Up to 3-7 working days processing
                </b-card-text>
              </b-card>
            </b-col>
            <b-col cols="6">
              <b-card
                title="Bank Transfer"
                style="max-width: 20rem;"
                class="m-auto cursor-pointer"
                @click="handleSetSelectedRefundOption(1)"
              >
                <img
                  src="@/assets/images/logo/merchant2.png"
                  alt=""
                  class="mb-2"
                >
                <b-card-text>
                  Up to 7-14 working days processing
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col v-else>
          <b-row>
            <b-col
              cols="4"
              class="text-center"
            >
              <b-card
                :title="refundType === 1 ? 'Bank Transfer' : 'Store Credit'"
                style="max-width: 20rem;"
                class="m-auto"
              >
                <img
                  v-if="refundType === 1 "
                  src="@/assets/images/logo/merchant2.png"
                  alt=""
                  class="mb-2"
                >

                <img
                  v-else
                  src="@/assets/images/logo/stack-coins.png"
                  alt=""
                  class="mb-2"
                >

                <b-card-text>
                  {{ refundType === 1 ? 'Up to 7-14 working days processing' : 'Up to 3-7 working days processing' }}
                </b-card-text>
              </b-card>
            </b-col>
            <b-col cols="8">
              <ValidationObserver
                v-slot="{ passes }"
                ref="requestRefund"
              >
                <form @submit.prevent="passes(handleSubmit)">
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <b-form-group label="Company Name">
                      <b-input
                        v-model="order.user.name"
                        label="text"
                        placeholder="Company Name"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Reason"
                      vid="reason_id"
                    >
                      <b-form-group label="Select a reason">
                        <VueSelect
                          v-model="form.reason_id"
                          label="text"
                          :options="reasonOptions"
                          :reduce="reason => reason.id"
                          placeholder="Please Select Reason"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col
                    v-if="refundType === 1"
                    cols="12"
                    class="mb-2"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      vid="bank_id"
                      name="Bank"
                    >
                      <b-form-group label="Select bank">
                        <VueSelect
                          v-model="form.bank_id"
                          label="text"
                          :options="bankOptions"
                          :reduce="bank => bank.id"
                          placeholder="Please select bank"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col
                    v-if="refundType === 1"
                    cols="12"
                    class="mb-2"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      vid="bank_account_number"
                      name="Bank Account Number"
                    >
                      <b-form-group label="Enter bank account number">
                        <b-input
                          v-model="form.bank_account_number"
                          label="text"
                          placeholder="Please enter bank account number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col
                    v-if="refundType === 2"
                    cols="12"
                    class="mb-2"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      vid="store_credit"
                      name="Store Credit"
                    >
                      <b-form-group label="Store Credit (RM)">
                        <b-input
                          v-model="form.store_credit"
                          label="text"
                          placeholder="Please enter refund amount (RM)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col
                    cols="12"
                    class="mx-auto"
                  >
                    <b-button
                      class="mt-1 w-100"
                      variant="primary"
                      type="submit"
                    >
                      <b-spinner
                        v-if="isLoading"
                        size="10"
                      />
                      <span v-else>
                        Submit
                      </span>
                    </b-button>
                    <b-button
                      class="mt-1 w-100"
                      variant="secondary"
                      type="button"
                      @click.prevent="refundType = null"
                    >
                      Back to Option
                    </b-button>
                  </b-col>
                </form>
              </ValidationObserver>
            </b-col>
          </b-row>

        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { VueSelect } from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import axios from '@axios'

export default {
  name: 'RequestRefund',
  components: {
    VueSelect,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    order: {
      required: true,
      type: [Object, Array],
    },
  },
  data() {
    return {
      reasonOptions: [],
      bankOptions: [],
      selectedReason: '',
      selectedBank: '',
      bankAccountNumber: '',
      form: {
        type: null,
        reason_id: null,
        bank_id: null,
        bank_account_number: null,
        store_credit: null,
      },
      isLoading: false,
      refundType: null,
    }
  },
  computed: {
    finalForm() {
      if (this.refundType === 1) {
        const {
          reason_id, bank_id, bank_account_number,
        } = this.form
        return {
          type: this.refundType,
          reason_id,
          bank_id,
          bank_account_number,
        }
      }
      const {
        reason_id, store_credit,
      } = this.form
      return {
        type: this.refundType,
        reason_id,
        store_credit,
      }
    },
  },
  created() {
    this.handleDataFecthing()
  },
  methods: {
    async handleSubmit() {
      this.isLoading = true
      const response = await axios.post(`refund-orders/${this.order.id}`, this.finalForm)

      if (response.status === 422) {
        this.$refs.requestRefund.setErrors(response.data.errors)
        this.isLoading = false
      } else if (response.status === 200) {
        this.$swal({
          title: 'Request Submitted!',
          icon: 'success',
          text: `You have successfully requested a refund for order ${this.order.reference_id}.`,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })

        this.$emit('success')
        this.isLoading = false
      } else {
        this.$swal({
          title: 'Error',
          icon: 'error',
          text: 'Something went wrong. Please try again.',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })

        this.isLoading = false
      }
    },
    async handleDataFecthing() {
      this.fetchReasons()
      this.fetchBanks()
    },
    async fetchReasons() {
      const response = await axios.get('/reasons?type=2')
      this.reasonOptions = response.data.map(i => ({
        text: i.name,
        id: i.id,
      }))
    },
    async fetchBanks() {
      const response = await axios.get('/banks')
      this.bankOptions = response.data.map(i => ({
        text: i.name,
        id: i.id,
      }))
    },
    handleSetSelectedRefundOption(value) {
      this.refundType = value
    },
  },
}
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('b-row',[_c('b-col',{staticClass:"text-center",attrs:{"cols":"4"}},[_c('b-card',{staticClass:"m-auto",staticStyle:{"max-width":"20rem"},attrs:{"title":_vm.type === 1 ? 'Bank Transfer' : 'Store Credit'}},[(_vm.type === 1 )?_c('img',{staticClass:"mb-2",attrs:{"src":require("@/assets/images/logo/merchant2.png"),"alt":""}}):_c('img',{staticClass:"mb-2",attrs:{"src":require("@/assets/images/logo/stack-coins.png"),"alt":""}}),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.type === 1 ? 'Up to 7-14 working days processing' : 'Up to 3-7 working days processing')+" ")])],1)],1),_c('b-col',{attrs:{"cols":"8"}},[_c('ValidationObserver',{ref:"refundDetails",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.handleSubmit)}}},[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Company Name"}},[_c('b-input',{attrs:{"label":"text","placeholder":"Company Name","disabled":""},model:{value:(_vm.order.user.name),callback:function ($$v) {_vm.$set(_vm.order.user, "name", $$v)},expression:"order.user.name"}})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Reason","vid":"reason_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Reason"}},[_c('VueSelect',{attrs:{"label":"text","clearable":false,"disabled":"","placeholder":"Please Select Reason"},model:{value:(_vm.selectedReason),callback:function ($$v) {_vm.selectedReason=$$v},expression:"selectedReason"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),(_vm.type === 1)?_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Bank ID","vid":"bank_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Bank"}},[_c('VueSelect',{attrs:{"clearable":false,"label":"text","disabled":"","placeholder":"Please select bank"},model:{value:(_vm.selectedBank),callback:function ($$v) {_vm.selectedBank=$$v},expression:"selectedBank"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1):_vm._e(),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Amount","vid":"amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Amount"}},[_c('b-input',{attrs:{"disabled":"","placeholder":"Please enter amount"},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=_vm._n($$v)},expression:"amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),(_vm.type === 1)?_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Bank Account Number","vid":"bank_account_number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Enter bank account number"}},[_c('b-input',{attrs:{"label":"text","placeholder":"Please enter bank account number"},model:{value:(_vm.bankAccountNumber),callback:function ($$v) {_vm.bankAccountNumber=$$v},expression:"bankAccountNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1):_vm._e(),(_vm.type === 2)?_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"vid":"store_credit","name":"Store Credit"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Store Credit (RM)"}},[_c('b-input',{attrs:{"label":"text","placeholder":"Please enter refund amount (RM)"},model:{value:(_vm.storeCredit),callback:function ($$v) {_vm.storeCredit=$$v},expression:"storeCredit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1):_vm._e(),_c('b-col',{staticClass:"mx-auto",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mt-2 w-100",attrs:{"variant":"primary","type":"submit"}},[(_vm.isLoading)?_c('b-spinner'):_c('span',[_vm._v(" Edit ")])],1)],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
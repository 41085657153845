<template>
  <validation-provider
    v-slot="{ errors }"
    :vid="vidName"
    name="Clients"
  >
    <v-select
      v-model="selectedClient"
      :options="clients"
      :clearable="false"
      label="name"
      append-to-body
      :calculate-position="withPopper"
      placeholder="Select Client/Market"
      @input="hadleSelectedClient"
    >
      <template #option="{ name, logo }">
        <img
          :src="logo"
          :alt="name"
          style="max-width:120px; max-height: 120px"
        >
        <span class="ml-1">{{ name }}</span>
      </template>
    </v-select>
    <span class="text-danger">{{ errors[0] }}</span>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { createPopper } from '@popperjs/core'
import vSelect from 'vue-select'
import axios from '@axios'

export default {
  name: 'MySelectClients',
  components: {
    vSelect,
    ValidationProvider,
  },
  props: {
    client: {
      type: Object,
      default: null,
    },
    vidName: {
      type: String,
      default: 'type',
    },
  },
  data() {
    return {
      clients: [],
      selectedClient: null,
    }
  },
  created() {
    this.fetchClients()
    this.selectedClient = this.client
  },
  methods: {
    async fetchClients() {
      const response = await axios.get('/clients')
      if (response.status === 200) {
        this.clients = response.data
      }
    },
    hadleSelectedClient() {
      this.$emit('set-selected-client', this.selectedClient)
    },
    withPopper(dropdownList, component, { width }) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      const tempDropDown = dropdownList
      tempDropDown.style.width = width
      tempDropDown.style.zIndex = '1300'

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, tempDropDown, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top',
              )
            },
          },
        ],
      })

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy()
    },
  },
}
</script>

<template>
  <b-row>
    <b-col cols="12">
      <app-collapse
        type="border"
        class="mb-2"
      >
        <app-collapse-item
          :is-visible="isVisible"
          :title="isEditVoucher ? 'Edit Voucher' : 'Generate Voucher'"
          @visible="showVoucherForm"
        >
          <b-form
            @submit.prevent="handleSubmitForm"
          >
            <b-row>
              <b-col cols="6">
                <b-form-group
                  label="Code Prefix"
                  label-for="code_prefix"
                >
                  <b-form-input
                    id="code_prefix"
                    v-model="form.prefix"
                    placeholder="Enter Code Prefix"
                    :readonly="isEditVoucher ? true : false"
                  />
                  <small class="text-danger">{{ errors.prefix ? errors.prefix[0] : '' }}</small>
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <b-form-group
                  label="Code Postfix"
                  label-for="code_postfix"
                >
                  <b-form-input
                    id="code_postfix"
                    v-model="form.postfix"
                    placeholder="Enter Code Postfix"
                    :readonly="isEditVoucher ? true : false"
                  />
                  <small class="text-danger">{{ errors.postfix ? errors.postfix[0] : '' }}</small>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mb-2">
              <b-col
                cols="12"
                lg="6"
              >
                <label for="start_date">Valid From</label>
                <flat-pickr
                  id="start_date"
                  v-model="form.valid_from"
                  class="form-control"
                  name="valid_from"
                  :config="fromDateConfig"
                />
                <small class="text-danger">{{ errors.valid_from ? errors.valid_from[0] : '' }}</small>
              </b-col>
              <b-col
                cols="12"
                lg="6"
              >
                <label for="end_date">Valid To</label>
                <flat-pickr
                  id="end_date"
                  v-model="form.valid_to"
                  class="form-control"
                  name="valid_to"
                  :config="toDateConfig"
                />
                <small class="text-danger">{{ errors.valid_to ? errors.valid_to[0] : '' }}</small>
              </b-col>
            </b-row>

            <b-row class="mb-2">
              <b-col cols="6">
                <b-form-group
                  label="Amount"
                  label-for="amount"
                >
                  <b-input-group>
                    <b-form-input
                      id="amount"
                      v-model="form.amount"
                      placeholder="Amount"
                      name="amount"
                      type="number"
                      :disabled="form.hasOrders"
                      step=".01"
                    />
                    <select
                      id="type"
                      v-model="form.type"
                      name="type"
                      :disabled="form.hasOrders"
                      class="form-select"
                      aria-label="Voucher Type"
                    >
                      <option
                        value="per_box"
                        selected
                      >
                        Per Box
                      </option>
                      <option value="per_order">
                        Per Order
                      </option>
                    </select>
                  </b-input-group>
                  <small class="text-danger">{{ errors.amount ? errors.amount[0] : '' }}</small>
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <b-form-group
                  label="Maximum Usage Limit"
                  label-for="max_usage"
                >
                  <v-select
                    v-model="form.max_usage"
                    :options="maxUsageOption"
                    :clearable="false"
                    name="max_usage"
                    label="text"
                    :reduce="max => max.value"
                  />
                  <small class="text-danger">{{ errors.max_usage ? errors.max_usage[0] : '' }}</small>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="4"
              >
                <b-input-group>
                  <b-form-input
                    placeholder="Preview Voucher Here"
                    class="text-primary"
                    :value="setCode()"
                    name="code_name"
                    readonly
                  />
                  <b-input-group-append>
                    <b-button
                      variant="primary"
                      type="submit"
                      :disabled="loading"
                    >
                      {{ isEditVoucher ? 'Edit Voucher' : 'Generate' }}
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors.code_name ? errors.code_name[0] : '' }}</small>
              </b-col>
            </b-row>
          </b-form>
        </app-collapse-item>
      </app-collapse>
    </b-col>
  </b-row>
</template>

<script>
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import axios from '@axios'

export default {
  name: 'CodeGenerator',
  components: {
    flatPickr,
    AppCollapse,
    AppCollapseItem,
    vSelect,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: () => {},
    },
    isEditVoucher: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: [],
      loading: false,
      form: {
        type: 'per_box',
        valid_from: null,
        valid_to: null,
        amount: null,
        max_usage: null,
        code_name: null,
        prefix: '',
        postfix: '',
      },
    }
  },
  computed: {
    fromDateConfig() {
      return {
        enableTime: false,
        dateFormat: 'Y-m-d',
        minDate: new Date(),
      }
    },
    toDateConfig() {
      return {
        enableTime: false,
        dateFormat: 'Y-m-d',
        minDate: this.form.valid_from,
      }
    },
    maxUsageOption() {
      const maxUsage = []
      Array(20).fill(0).forEach((x, number) => {
        const times = number + 1
        maxUsage.push({
          value: times,
          text: `${times} ${times > 1 ? 'Times' : 'Time'} Per User`,
        })
      })
      return maxUsage
    },
  },
  watch: {
    formData(value) {
      if (value) {
        this.form = value
      }
    },
  },
  methods: {
    setCode() {
      if (this.form.prefix && this.form.amount && this.form.postfix) {
        return `${this.form.prefix}${this.form.amount}${this.form.postfix}`.toUpperCase()
      }
      return ''
    },
    handleSubmitForm() {
      if (this.isEditVoucher) {
        this.updateVoucherCode()
      } else {
        this.createVoucherCode()
      }
    },
    async createVoucherCode() {
      this.loading = true
      this.form.code_name = this.setCode()
      const response = await axios.post('/admin/vouchers', this.form)
      if (response.status === 422) {
        this.errors = response.data.errors
        this.loading = false
        return
      }

      if (response.status === 200) {
        this.resetForm()
        this.$emit('fetchVouchers')
        this.$swal({
          icon: 'success',
          title: 'Created!',
          text: 'Voucher Successfully Created',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }
      this.loading = false
    },
    async updateVoucherCode() {
      this.loading = true
      this.form.code_name = this.setCode()
      const response = await axios.patch(`/admin/vouchers/${this.form.id}`, this.form)
      if (response.status === 422) {
        this.errors = response.data.errors
        this.loading = false
        return
      }

      if (response.status === 200) {
        this.resetForm()
        this.$emit('fetchVouchers')
        this.$emit('setIsEditVoucher', false)
        this.$swal({
          icon: 'success',
          title: 'Updated!',
          text: 'Voucher Successfully Updated',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }
      this.loading = false
    },
    resetForm() {
      this.errors = []
      this.form = {
        valid_from: null,
        valid_to: null,
        amount: null,
        max_usage: null,
        code_name: null,
        prefix: '',
        postfix: '',
      }
    },
    showVoucherForm(val = false) {
      this.resetForm()
      this.$emit('visible', val)
    },
  },

}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="4"
          class="text-center"
        >
          <b-card
            :title="type === 1 ? 'Bank Transfer' : 'Store Credit'"
            style="max-width: 20rem;"
            class="m-auto"
          >
            <img
              v-if="type === 1 "
              src="@/assets/images/logo/merchant2.png"
              alt=""
              class="mb-2"
            >

            <img
              v-else
              src="@/assets/images/logo/stack-coins.png"
              alt=""
              class="mb-2"
            >

            <b-card-text>
              {{ type === 1 ? 'Up to 7-14 working days processing' : 'Up to 3-7 working days processing' }}
            </b-card-text>
          </b-card>
        </b-col>
        <b-col cols="8">
          <ValidationObserver
            v-slot="{ passes }"
            ref="refundDetails"
          >
            <form @submit.prevent="passes(handleSubmit)">
              <b-col
                cols="12"
                class="mb-2"
              >
                <b-form-group label="Company Name">
                  <b-input
                    v-model="order.user.name"
                    label="text"
                    placeholder="Company Name"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="mb-2"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="Reason"
                  vid="reason_id"
                >
                  <b-form-group label="Reason">
                    <VueSelect
                      v-model="selectedReason"
                      label="text"
                      :clearable="false"
                      disabled
                      placeholder="Please Select Reason"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col
                v-if="type === 1"
                cols="12"
                class="mb-2"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="Bank ID"
                  vid="bank_id"
                >
                  <b-form-group label="Bank">
                    <VueSelect
                      v-model="selectedBank"
                      :clearable="false"
                      label="text"
                      disabled
                      placeholder="Please select bank"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col
                cols="12"
                class="mb-2"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="Amount"
                  vid="amount"
                >
                  <b-form-group label="Amount">
                    <b-input
                      v-model.number="amount"
                      disabled
                      placeholder="Please enter amount"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col
                v-if="type === 1"
                cols="12"
                class="mb-2"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="Bank Account Number"
                  vid="bank_account_number"
                  rules="required"
                >
                  <b-form-group label="Enter bank account number">
                    <b-input
                      v-model="bankAccountNumber"
                      label="text"
                      placeholder="Please enter bank account number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col
                v-if="type === 2"
                cols="12"
                class="mb-2"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  vid="store_credit"
                  name="Store Credit"
                >
                  <b-form-group label="Store Credit (RM)">
                    <b-input
                      v-model="storeCredit"
                      label="text"
                      placeholder="Please enter refund amount (RM)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col
                cols="12"
                class="mx-auto"
              >
                <b-button
                  class="mt-2 w-100"
                  variant="primary"
                  type="submit"
                >
                  <b-spinner v-if="isLoading" />
                  <span v-else>
                    Edit
                  </span>
                </b-button>
              </b-col>
            </form>
          </ValidationObserver>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { VueSelect } from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import axios from '@axios'

export default {
  name: 'RefundDetails',
  components: {
    VueSelect,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    order: {
      required: true,
      type: [Object, Array],
    },
  },
  data() {
    return {
      bankOptions: [],
      selectedReason: this.order.refund.reason.name,
      selectedBank: this.order.refund.bank.name,
      bankAccountNumber: this.order.refund.bank_account_number,
      amount: this.order.refund.amount,
      type: this.order.refund.type.value,
      storeCredit: this.order.refund.store_credit,
      isLoading: false,
    }
  },
  methods: {
    async handleSubmit() {
      this.isLoading = true
      const response = await axios.patch(`refund-orders/${this.order.refund.id}`, {
        type: this.type,
        bank_account_number: this.bankAccountNumber,
        store_credit: this.storeCredit,
      })

      if (response.status === 422) {
        this.$refs.refundDetails.setErrors(response.data.errors)
        this.isLoading = false
      } else if (response.status === 200) {
        this.$swal({
          title: 'Update Successful!',
          icon: 'success',
          text: `You have successfully edited the refund details for order ${this.order.reference_id}.`,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })

        this.$emit('success')
        this.isLoading = false
      } else {
        this.$swal({
          title: 'Error',
          icon: 'error',
          text: 'Something went wrong. Please try again.',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })

        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
</style>

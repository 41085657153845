<template>
  <div>
    <ValidationObserver
      v-slot="{ passes }"
      ref="editOrder"
    >
      <b-row>
        <b-col cols="12">
          <statistic-card-horizontal
            :statistic="order.reference_id"
            statistic-title="Order ID"
            icon="TruckIcon"
            class="border shadow-none"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <form @submit.prevent="passes(handleSubmit)">
            <b-card class="border shadow-none">
              <b-card-body>
                <b-row>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <b-form-group label="PO Number">
                      <ValidationProvider
                        #default="{ errors }"
                        name="PO Number"
                        rules="required"
                      >
                        <b-form-input
                          id="po_number"
                          v-model="form.po_number"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Area"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <b-form-group label="PO Expiry Date">
                      <ValidationProvider
                        #default="{ errors }"
                        name="PO Expiry Date"
                        rules="required"
                      >
                        <flat-pickr
                          id="po_expiry_date"
                          v-model="form.po_expiry_date"
                          class="form-control"
                          :config="{enableTime: true, dateFormat: 'Y-m-d', minDate: defaultPoExpiryDate}"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <b-form-group label="Delivery Date">
                      <ValidationProvider
                        #default="{ errors }"
                        name="Delivery Date"
                        rules="required"
                      >
                        <flat-pickr
                          id="delivery_date"
                          v-model="form.delivery_date"
                          class="form-control"
                          :config="{dateFormat: 'Y-m-d H:i', minDate: defaultDeliveryDateTime}"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <b-form-group label="Invoice Number">
                      <ValidationProvider
                        #default="{ errors }"
                        name="Invoice Number"
                        rules="required"
                      >
                        <b-form-input
                          id="invoice_number"
                          v-model="form.invoice_number"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Invoice Number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
            <b-col class="mb-2 text-center">
              <b-button
                variant="danger"
                :disabled="isLoading"
                @click.prevent="handleSubmit"
              >
                <b-spinner v-if="isLoading" />
                <span v-else>
                  Submit
                </span>
              </b-button>
            </b-col>
          </form>
        </b-col>
      </b-row>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import axios from '@axios'

export default {
  name: 'EditOrder',
  components: {
    ValidationObserver,
    ValidationProvider,
    flatPickr,
    StatisticCardHorizontal,
  },
  props: {
    order: {
      required: true,
      type: [Array, Object],
    },
  },
  data() {
    return {
      form: {
        po_number: this.order.po_number,
        po_expiry_date: this.order.po_expiry_date,
        delivery_date: this.order.delivery_date,
        invoice_number: this.order.invoice_number,
      },
      isLoading: false,
    }
  },
  computed: {
    defaultDeliveryDateTime() {
      const today = new Date()
      today.setHours(today.getHours() + 4)
      return today
    },
    defaultPoExpiryDate() {
      return new Date()
    },
  },
  methods: {
    async handleSubmit() {
      this.isLoading = true
      const response = await axios.patch(`orders/${this.order.id}`, this.form)

      if (response.status === 422) {
        this.$refs.editOrder.setErrors(response.data.errors)
        this.isLoading = false
      } else if (response.status === 200) {
        this.$swal({
          title: 'Updated Successfully!',
          icon: 'success',
          text: `You have successfully updated the details of ${this.order.reference_id}.`,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })

        this.$emit('success')
      } else {
        this.$swal({
          title: 'Error',
          icon: 'error',
          text: 'Something went wrong. Please try again.',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })

        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<template>
  <div>
    <b-card class="shadow-none">
      <ValidationObserver
        v-slot="{ passes }"
        ref="updateStatus"
      >
        <b-row>
          <b-col>
            <form @submit.prevent="passes(handleSubmit)">
              <b-col cols="12">
                <ValidationProvider
                  #default="{ errors }"
                  name="Delivery Status"
                  rules="required"
                >
                  <b-form-group label="Update Delivery Status">
                    <VueSelect
                      v-model="selectedStatus"
                      label="text"
                      :options="statusOptions"
                      placeholder="Select Delivery Status"
                    />
                  </b-form-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-col>
              <b-col
                cols="12"
                lg="4"
                class="mx-auto"
              >
                <b-button
                  class="mt-2 w-100"
                  variant="primary"
                  :disabled="!selectedStatus"
                  @click="handleUpdateStatus"
                >
                  <b-spinner v-if="isLoading" />
                  <span v-else>
                    Submit
                  </span>
                </b-button>
              </b-col>
            </form>
          </b-col>
        </b-row>

      </ValidationObserver>
    </b-card>
  </div>
</template>

<script>
import { STATUS_OPTIONS } from '@/common/Constant'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { VueSelect } from 'vue-select'
import axios from '@axios'

export default {
  name: 'UpdateStatus',
  components: {
    ValidationObserver,
    ValidationProvider,
    VueSelect,
  },
  props: {
    order: {
      required: true,
      type: [Array, Object],
    },
  },
  data() {
    return {
      selectedStatus: null,
      statusOptions: STATUS_OPTIONS.filter(i => i.id !== 2 && i.id !== this.order.status.value),
      isLoading: false,
    }
  },
  methods: {
    async handleUpdateStatus() {
      this.isLoading = true
      const response = await axios.patch(`order-status/${this.order.id}`, {
        status: this.selectedStatus.id,
      })

      if (response.status === 422) {
        this.$refs.updateStatus.setErrors(response.data.errors)
        this.isLoading = false
      } else if (response.status === 200) {
        this.$swal({
          title: 'Status Updated!',
          text: `You have successfully updated the delivery status of ${this.order.reference_id} to "${this.selectedStatus.text}".`,
          icon: 'success',
          customClass: {
            confirmButton: 'bg-success',
          },
        })

        this.$emit('success')
        this.isLoading = false
      } else {
        this.$swal({
          title: 'Error',
          icon: 'error',
          text: 'Something went wrong. Please try again.',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })

        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
</style>

<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-row>
          <b-col
            cols="12"
            lg="3"
          >
            <statistic-card-horizontal
              :statistic="order.reference_id"
              statistic-title="Order ID"
              icon="TruckIcon"
              class="border shadow-none"
            />
          </b-col>
        </b-row>

        <b-card class="border shadow-none">
          <b-row>
            <b-col>
              <statistic-card-vertical
                :statistic="order.po_number"
                statistic-title="PO Number"
                color="purple"
                icon="BriefcaseIcon"
                class="m-0"
              />
            </b-col>
            <b-col>
              <statistic-card-vertical
                :statistic="$helpers.formatDate(order.po_expiry_date)"
                color="primary"
                statistic-title="PO Expiry Date"
                icon="XCircleIcon"
                class="m-0"
              />
            </b-col>
            <b-col>
              <statistic-card-vertical
                :statistic="order.quantity"
                color="success"
                statistic-title="Quantity"
                icon="LayersIcon"
                class="m-0"
              />
            </b-col>
            <b-col>
              <statistic-card-vertical
                :statistic="order.shipment_type"
                color="turquoise"
                statistic-title="Shipment Type"
                icon="TruckIcon"
                class="m-0"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <!--Sender Address-->
      <b-col cols="6">
        <b-card class="border shadow-none">
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h6>
                  <strong>SENDER ADDRESS</strong>
                </h6>
                <b-row>
                  <b-col cols="8">
                    {{ order.sender.complete }}
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12">
                <b-row>
                  <b-col cols="6">
                    <h6><strong>Person in-charge</strong></h6>
                    {{ order.sender.name }}
                  </b-col>
                  <b-col cols="6">
                    <h6><strong>Mobile Number</strong></h6>
                    {{ order.sender.phone }}
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>

      <!--RECEIPIENT ADDRESS-->
      <b-col cols="6">
        <b-card class="border shadow-none">
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h6>
                  <b>RECIPIENT ADDRESS</b>
                </h6>
                <b-row>
                  <b-col cols="8">
                    {{ order.recipient.complete }}
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12">
                <b-row>
                  <b-col cols="6">
                    <h6><strong>Person in-charge</strong></h6>
                    {{ order.recipient.name }}
                  </b-col>
                  <b-col cols="6">
                    <h6><strong>Mobile Number</strong></h6>
                    {{ order.recipient.phone }}
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-card class="border shadow-none">
      <b-card-body>
        <ValidationObserver
          v-slot="{ passes }"
          ref="assignForm"
        >
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="passes(handleSubmit)"
          >
            <b-row>
              <b-col cols="4">
                <h6 class="mb-2">
                  <b>SELECT SMARTTRUCK</b>
                </h6>
                <ValidationProvider
                  #default="{ errors }"
                  name="Smarttruck"
                  rules="required"
                >
                  <VueSelect
                    v-model="selectedSmarttruck"
                    :options="smarttruckOptions"
                    :clearable="false"
                    label="company_name"
                    placeholder="Please Select Smarttruck"
                    @input="getSmartVehicleAndDrivers"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-col>
              <b-col cols="4">
                <h6 class="mb-2">
                  <b>SELECT VEHICLE PLATE NUMBER</b>
                </h6>
                <ValidationProvider
                  #default="{ errors }"
                  name="Vehicle"
                  rules="required"
                >
                  <VueSelect
                    v-model="selectedVehicle"
                    :options="vehicleOptions"
                    :clearable="false"
                    label="plate"
                    placeholder="Please Select Vehicle"
                  >
                    <template #option="{ plate, image }">
                      <b-img
                        width="50"
                        :src="$s3URL + 'uploads/user/' + image"
                        class="mr-2"
                      />
                      <span>{{ plate }}</span>
                    </template>
                  </VueSelect>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-col>
              <b-col cols="4">
                <h6 class="mb-2">
                  <b>SELECT DRIVER</b>
                </h6>
                <ValidationProvider
                  #default="{ errors }"
                  name="Driver"
                  rules="required"
                >
                  <VueSelect
                    v-model="selectedDriver"
                    :options="driverOptions"
                    :clearable="false"
                    label="d_name"
                    placeholder="Please Select Driver"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-col>
            </b-row>

            <b-row class="mt-4">
              <b-col
                cols="12"
                lg="2"
                class="mx-auto"
              >
                <b-button
                  :disabled="isLoading"
                  variant="primary"
                  type="submit"
                  class="w-100"
                >
                  <b-spinner v-if="isLoading" />
                  <span v-else>
                    Assign
                  </span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { VueSelect } from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import axios from '@axios'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

export default {
  name: 'AssignSmarttruck',
  components: {
    VueSelect,
    ValidationObserver,
    ValidationProvider,
    StatisticCardHorizontal,
    StatisticCardVertical,
  },
  props: {
    order: {
      required: true,
      type: [Array, Object],
    },
  },
  data() {
    return {
      isLoading: false,
      selectedSmarttruck: null,
      selectedDriver: '',
      selectedVehicle: '',
      smarttruckOptions: [],
      vehicleOptions: [],
      driverOptions: [],
      form: {},
    }
  },
  created() {
    this.handlePayloads()
  },
  methods: {
    handlePayloads() {
      this.getSmarttruckUserSelectField()
      this.getSmartVehicleAndDrivers()
    },

    async getSmarttruckUserSelectField() {
      const response = await axios.get('smarttruck-users?for_select_field=true')
      this.smarttruckOptions = response.data.data
    },

    async getSmartVehicleAndDrivers() {
      const smarttruckId = this.selectedSmarttruck ? this.selectedSmarttruck.id : 1
      const response = await axios.get(`getSmartVehicleAndDriver/${smarttruckId}`)
      this.vehicleOptions = response.data.vehicles
      this.driverOptions = response.data.drivers
    },

    async handleSubmit() {
      this.isLoading = true
      const response = await axios.patch(`order-status/${this.order.id}`, {
        status: 2,
        smarttruck_id: this.selectedSmarttruck.id,
        vehicle_id: this.selectedVehicle.id,
        driver_id: this.selectedDriver.d_id,
      })

      if (response.status === 422) {
        this.$refs.assignForm.setErrors(response.data.errors)
        this.isLoading = false
      } else if (response.status === 200) {
        this.$swal({
          title: 'Success!',
          text: `You have successfully assigned ${this.order.reference_id} to ${this.selectedSmarttruck.company_name}.`,
          icon: 'success',
          customClass: {
            confirmButton: 'bg-primary',
          },
        })

        this.isLoading = false
        this.$emit('success')
      } else {
        this.$swal({
          title: 'Error',
          icon: 'error',
          text: 'Something went wrong. Please try again.',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })

        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
</style>

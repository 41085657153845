<template>
  <div class="p-1">
    <b-form-group label="Transaction ID">
      <b-form-input
        v-model="order.payment.transaction_id"
        readonly
      />
    </b-form-group>

    <b-form-group label="Payment Type">
      <b-form-input
        v-model="order.payment.payment_type"
        readonly
      />
    </b-form-group>

    <b-form-group label="Reference Number">
      <b-form-input
        v-model="order.payment.reference_number"
        readonly
      />
    </b-form-group>

    <b-form-group label="Status">
      <b-form-input
        v-model="order.payment.status.description"
        readonly
      />
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'PaymentDetails',
  props: {
    order: {
      required: true,
      type: [Object],
    },
  },
}
</script>

<template>
  <div>
    <b-row class="d-flex align-items-center">
      <b-col
        md="4"
        class="text-center"
      >
        <validation-provider
          v-slot="{ errors }"
          vid="client.pic"
          name="logo"
        >
          <ImageUploader
            :image="form.image_path"
            :size="'150px'"
            :is-editable="isEditable"
            @set-image="handleSetImage"
          />
          <span class="text-danger">{{ errors[0] }}</span>
        </validation-provider>
      </b-col>
      <b-col md="8">
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Main Name"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="client.name"
                name="Main Name"
              >
                <b-form-input
                  v-model="form.client.name"
                  placeholder="Enter Main Name (e.g. MyDin)"
                  :readonly="!isEditable"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Type Of Market"
            >
              <MySelectTypeOfMarket
                :vid-name="'client.type'"
                :market="form.client.type"
                :is-editable="isEditable"
                @set-selected-market="handleSetSelectedMarket"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import MySelectTypeOfMarket from '@/components/MySelectTypeOfMarket.vue'
import ImageUploader from '@/components/ImageUploader.vue'

export default {
  name: 'MarketForm',
  components: {
    ValidationProvider,
    MySelectTypeOfMarket,
    ImageUploader,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleSetSelectedMarket(value) {
      this.form.client.type = value
    },
    handleSetImage(data) {
      this.form.image_path = data.image_path
      this.form.client.image_path = data.image_path
      this.form.client.pic = data.file_name
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
